import $router from "@/router";
import _ from "@/boot/lodash";
import type { ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import type { QUERY_PARAMS } from "@/data/constants";
import type { Route } from "vue-router";

export interface IUIRouterState {
  currentRoute: Route | null;
}

const initialState = (): IUIRouterState => ({
  currentRoute: null
});

const actions: ActionTree<IUIRouterState, IState> = {
  removeQueryParams: (context, params: QUERY_PARAMS[] = []) => {
    const query = _.omit($router.currentRoute.query, params);
    return $router.replace({ query }).catch(err => err);
  }
};

const mutations: MutationTree<IUIRouterState> = {
  setCurrentRoute: (state, route: Route) => {
    state.currentRoute = route;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
};
